<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/course' }">我的课堂</el-breadcrumb-item>
                <el-breadcrumb-item>新建课时</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="mini" >

                <el-form-item label="名称：" label-position="left" prop="name" class="input">
                    <el-input style="width: 200px"  v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="类型：">
                    <el-radio-group v-model="form.type" size="mini" >
                        <el-radio-button :label="1">视频</el-radio-button>
                        <el-radio-button :label="2">图文</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <div v-if="form.type === 1">
                    <el-form-item label="选择视频：" label-position="left"  prop="videoId" >
                        <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
                    </el-form-item>

                    <el-form-item label="视频封面：" label-position="left" >

                        <el-image :src="form.pic" class="videoImage" v-if="form.pic !== ''"></el-image>
                    </el-form-item>
                </div>

                <div v-if="form.type === 2">
                    <el-form-item label="内容：" label-position="left" prop="content">
                        <editor @onChange="onChange" :content="form.content"></editor>
                    </el-form-item>
                </div>

                <el-form-item label="所属章节：" label-position="left" class="input"  prop="chapter">
                    <el-select v-model="form.chapterId" clearable placeholder="请选择">
                        <el-option
                                v-for="item in chapters"
                                :key="item.id"
                                :label="item.name"
                                :disabled="item.status === 0"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="排序：" label-position="left" class="input">
                    <el-input style="width: 200px"  v-model="form.sort"></el-input>
                </el-form-item>

                <el-form-item label="试学：" label-position="left" class="input" >
                    <el-switch v-model="form.free" :active-value="1" :inactive-value="0" @change="setPayStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{payStatusTxt}}</span>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>

                <el-form-item label="定时上架：" label-position="left" class="input" >
                    <el-switch v-model="form.timing" @change="setTiming">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{form.timing ? '是' : '否'}}</span>
                </el-form-item>


                <el-form-item label="上架时间：" label-position="left" class="input" v-if="form.timing" >
                    <el-date-picker
                            v-model="form.time"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit('form')">保存</el-button>
                    <router-link to="/course" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import editor from '../../../components/editor'
    import selectVideo from '../../../components/selectVideo'
    import {mapActions} from "vuex";
    export default {
        name: "addClass",
        components:{editor,selectVideo},
        data() {
            return {
                statusTxt:'上架',
                payStatusTxt:'关闭',
                loading:false,
                inputVisible: false,
                inputValue: '',
                uploadPath:'course',
                chapters:[],
                courseId:0,
                pic:'',
                form: {
                    status:true,
                    free:0,
                    pic:'',
                    name:'',
                    type:1,
                    content:'',
                    chapterId:'',
                    videoId:0,
                    duration:0,
                    size:0,
                    time:'',
                    timing:false,
                    sort:0
                },
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                        { min: 2, max: 24, message: '长度在 2 到 24 个字符', trigger: 'blur' }
                    ],
                    videoId: [
                        { type: 'number', required: true, message: '请选择视频', trigger: 'change' }
                    ],
                    chapterId: [
                        { type: 'number', required: true, message: '请选择所属章节', trigger: 'change' }
                    ],
                    content: [
                        { type: 'string', required: true, message: '请输入内容', trigger: 'blur' }
                    ],

                },

            }
        },
        methods: {
            ...mapActions('course',['createCourse','getChapterList','addClass']),
            uploadSuccess(path){
                this.form.pic = path
            },
            setTiming(v){
                if(v === true){
                    this.form.status = false
                }
            },
            onSelect(video){
                console.log(video)
                this.form.pic = video.pic
                this.form.videoId = video.id
                this.form.duration = video.duration
                this.form.size = video.size
            },
            onDelete(){
                this.form.pic = ''
            },
            async onSubmit(formName) {

                console.log(this.form)


                if(!this.validateForm(formName))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let _this = this
                this.loading = true

                console.log(this.form)
                console.log('-----------------')


                this.addClass(this.form).then(res => {
                    console.log(res)
                    if(res.ret === 0)
                    {
                        this.$message.success('新增成功！')
                        this.loading = false

                        this.$router.push('/course/detail/'+this.courseId+'/manage')
                    }
                })



            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
            setStatus(status){
                if(this.form.timing === true){
                    this.$message.info('已开启定时上架，无法设置上架')
                    this.form.status = false
                    return
                }
                console.log(status)
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            setPayStatus(){
                if(this.form.free === 1){
                    this.payStatusTxt = '开启'
                }else{
                    this.payStatusTxt = '关闭'
                    this.form.amount = 0
                    this.form.real_amount = 0
                    this.form.indate = 0
                }

            },
            handleClose(tag) {
                this.form.tags.splice(this.form.tags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm() {
                let inputValue = this.inputValue;
                if (inputValue) {
                    this.form.tags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            onChange(content){
                console.log(content)
                this.form.content = content
            },
        },
        mounted() {
            this.courseId = this.$route.params.id
            this.getChapterList({courseId:this.courseId}).then(res => {
                console.log(res)
                if(res.ret === 0 )
                {
                    this.chapters = res.data
                }
            })
        }
    }
</script>

<style>
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .videoImage{
        width: 200px;
        height: 120px;
    }
</style>
